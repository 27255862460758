//***************** Dots Loader **************************//
.dots-loader {
    width: 100%;
    text-align: center;
}
.dot-elastic {
    position: relative;
    width: 10px;
    height: 10px;
    @include radius(5px);
    background-color: $primary600;
    color: $primary600;
    animation: dotElastic 1s infinite linear;
    margin: 0 5px;
}

.dot-elastic::before,
.dot-elastic::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot-elastic::before {
    left: -15px;
    width: 10px;
    height: 10px;
    @include radius(5px);
    background-color: $primary600;
    color: $primary600;
    animation: dotElasticBefore 1s infinite linear;
}

.dot-elastic::after {
    left: 15px;
    width: 10px;
    height: 10px;
    @include radius(5px);
    background-color: $primary600;
    color: $primary600;
    animation: dotElasticAfter 1s infinite linear;
}

@keyframes dotElasticBefore {
    0% {
        transform: scale(1, 1);
    }
    25% {
        transform: scale(1, 1.5);
    }
    50% {
        transform: scale(1, 0.67);
    }
    75% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1, 1);
    }
}

@keyframes dotElastic {
    0% {
        transform: scale(1, 1);
    }
    25% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1, 1.5);
    }
    75% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1, 1);
    }
}

@keyframes dotElasticAfter {
    0% {
        transform: scale(1, 1);
    }
    25% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1, 0.67);
    }
    75% {
        transform: scale(1, 1.5);
    }
    100% {
        transform: scale(1, 1);
    }
}

.stage {
    @include flexprop;
    @include flexalign(center);
    @include flexjustify(center);
    position: relative;
    padding: 1rem 0;
    overflow: hidden;
    width: 100%;
}

//***************** Spinner **************************//

.loader-parent {
    z-index: 1;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
}

.loader {
    @include border(10px, $loader-bg);
    @include radius(50%);
    border-top: 10px solid $primary600;
    width: 70px;
    height: 70px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
