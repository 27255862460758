.country-field {
    .flag-dropdown {
        height: 44px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background-color: transparent !important;
        @include border(1px, #dbe4ed!important);
        border-right: 1px solid $gray300 !important;
        .selected-flag {
            width: 50px;
            padding: 0 12px;
            &:focus {
                @include border(1px, $primary600);
                border-right: 1px solid $primary600 !important;
            }
        }
    }
    .react-tel-input {
        height: 44px;
        width: 50px;
    }
    .react-tel-input .form-control {
        display: none;
    }
}
.location-search {
    .search-wrapper {
        max-width: 100%;
        width: 100%;
        .css-1s2u09g-control {
            padding-left: 50px;
            background-image: url("../images/search-icon.svg");
            background-repeat: no-repeat;
            background-position: 18px center !important;
            &:focus {
                background-color: #ffffff;
            }
        }
        .css-1pahdxg-control {
            padding-left: 50px;
            background-image: url("../images/search-icon.svg");
            background-repeat: no-repeat;
            background-position: 18px center !important;
            &:focus {
                background-color: #ffffff;
            }
        }
    }
}

.phone-number-field {
    display: flex;
    .input-wrapper {
        width: calc(100% - 50px);
        margin-bottom: 0 !important;
        input {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}
