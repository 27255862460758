.user-detail-wrap {
    .filter-header {
        margin-bottom: 22px;
    }

    .breadcrumb-left {
        @include flexprop;
        @include flexalign(center);

        label {
            font-size: 18px;
            margin-right: 15px;
            margin-bottom: 0;
            font-weight: $weight-700;
        }

        .react-select,
        .input-wrapper {
            width: 180px;

            &.mb-0 {
                margin-bottom: 0;
            }
        }
    }
}

.user-profile-sec {
    background: $white;
    @include radius(12px);
    padding: 30px 24px 12px;
    margin-bottom: 22px;
    .skill-contents {
        &.pl-75 {
            padding-left: 74px;
        }
    }
    &.pb-20 {
        padding: 30px 24px 20px;
    }

    .user-info-sec {
        padding: 0 0 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid $border300;
        @include flexprop;
        @include flexalign(center);
        @include flexjustify(space-between);

        .user-info-left {
            @include flexprop;

            .user-left {
                width: 72px;

                .user-image {
                    figure {
                        width: 72px;
                        height: 72px;
                        @include radius(50%);
                        padding: 2px;
                        @include border(2px, rgba(125, 133, 146, 0.202032));

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            @include radius(50%);
                        }
                    }
                }
            }

            .user-right {
                width: calc(100% - 72px);
                padding: 3px 16px;
            }
        }

        .user-info-right {
            .button-sec {
                .btn {
                    &.small {
                        min-width: 90px;
                    }
                }
            }
        }
    }

    .user-description-wrap {
        h3 {
            font-weight: $weight-700;
            font-size: 22px;
            line-height: 30px;
            color: $seconday-black;
        }

        .user-description {
            &.bgred {
                background: rgba(255, 39, 91, 0.11);
                color: $danger;
            }

            &.bgblue {
                background: rgba(36, 131, 197, 0.11);
                color: $primary600;
            }

            &.bggreen {
                background: $light-green;
                color: $green100;
            }

            &.bglightblue {
                background: rgba(143, 152, 255, 0.11);
                color: $blue300;
            }

            @include radius(8px);
            padding: 7px 12px;
            display: inline-block;
            margin-top: 5px;

            p {
                font-weight: $weight-700;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
            }
        }
    }

    .user-detail-wrap {
        .text-field {
            @include radius(5px);
            padding: 10.5px 12px;
            background: $white;
            @include border(1px, $gray300);
            color: $gray500;
            width: 100%;
            font-size: 14px;
            font-family: $font-Montserrat;
            line-height: 1.5;
            @include flexprop;
            @include flexalign(center);
            margin-bottom: 18px;
            position: relative;

            p {
                font-weight: $weight-400;
                font-size: 14px;
                color: $light-text;
                line-height: 24px;
            }

            &.mb-0 {
                margin-bottom: 0;
            }

            .left-icon {
                position: absolute;
                right: 12px;
                top: 10px;
            }

            img {
                height: 24px;
            }
        }

        h4 {
            margin-bottom: 15px;
            font-weight: $weight-700;
            font-size: 16px;
            color: $seconday-black;
        }

        .goals-section {
            margin-bottom: 24px;
            padding: 0 0 12px;
            border-bottom: 1px solid $border300;

            &:last-child {
                margin-bottom: 0;
                padding: 0;
                border-bottom: none;
            }

            .text-field {
                @include flexprop;
                @include flexalign(center);
                @include flexjustify(space-between);

                .left-icon {
                    figure {
                        width: 24px;
                        height: 24px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
    .btnStylling{
        
width: 55px;
// height: 24px;
flex-direction: column;
justify-content: center;
color: var(--White, #FFF);
text-align: center;
/* Components/Buttons */
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 150% */
background-color: #000D14;
margin-left: 30px !important;
border-radius: 4px;
display: flex;
padding: 1px 45px;
justify-content: center;
align-items: center;
gap: 4px;
// flex: 1 0 0;

    }
    .MuiOutlinedInput-notchedOutline{
        border: none !important;
    }

    .numberbutton{
        border-radius: 12px;
border: 1px solid var(--neutral-200, #EAF0F5);
color: var(--neutral-900, #273239);
text-align: center;
/* Heading/H3 */
font-family: Montserrat;
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: 125%; /* 27.5px */
letter-spacing: 0.5px;
display: flex;
padding: 14px 12px;
flex-direction: column;
align-items: flex-start;
gap: 10px;
    }

    .selectbuttonstyliing:before {
        border-bottom: 1px solid rgba(110, 52, 52, 0.42) !important;
        font-size: 30px;
    }
    .heading-sec {
        padding: 0 0 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid $border300;
        @include flexprop;
        @include flexalign(center);
        @include flexjustify(space-between);

        &.mb-12 {
            margin-bottom: 12px;
        }

        h3 {
            font-weight: $weight-700;
            font-size: 22px;
            color: $seconday-black;
        }

        .button-sec {
            .btn {
                &.small {
                    min-width: 90px;
                }
            }
        }
    }

    // coach-detail-skill-sec
    .skill-sec {
        @include flexprop;
        @include flexalign(center);

        ul {
            @include flexprop;
            @include flexalign(center);
            overflow-x: auto;
            padding-bottom: 10px;

            li {
                margin-top: 18px;
                background: $primary600;
                @include radius(8px);
                padding: 14px 15px;
                margin-right: 24px;
                font-weight: $weight-500;
                font-size: 14px;
                color: $white;
                white-space: nowrap;
                position: relative;

                figure {
                    display: none;

                    &.remove-icon {
                        position: absolute;
                        top: -18px;
                        right: 0;
                        width: 18px;
                        height: 18px;
                        cursor: pointer;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                &:hover {
                    figure {
                        display: block;
                    }
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .add-button {
            flex: 0 0 38px;
            max-width: 38px;
            padding-bottom: 10px;
            margin-left: 24px;
            margin-top: 18px;

            button {
                width: 100%;
                background: rgba(36, 131, 197, 0.1);
                @include radius(8px);
                padding: 10px 12px;

                img {
                    max-width: 14px;
                }
            }
        }

        .skill-add-sec {
            flex: 0 0 144px;
            max-width: 144px;
            padding-bottom: 10px;
            margin-left: 24px;
            margin-top: 18px;

            input[type="text"].height {
                height: 42px;
            }
        }
    }

    .pdf-sec {
        &.pl-74 {
            padding-left: 74px;
        }

        &.pb-30 {
            padding-bottom: 30px;
        }

        @include flexprop;
        @include flexalign(center);

        .pdf-content {
            padding-left: 15px;

            p {
                font-weight: $weight-500;
                font-size: 14px;
                line-height: 170%;
                color: $black;

                &.blue-text {
                    color: $gray600;
                }
            }
        }
    }

    hr.horozontal-border {
        margin-left: auto;
        border: 1px solid $gray300;
    }
}

.title {
    font-weight: $weight-700;
    font-size: 22px;
    color: $black;
    margin-bottom: 15px;
}

.main-heading {
    @include flexprop;
    @include flexalign(center);
    padding-bottom: 24px;

    &.pt-14 {
        padding-top: 14px;
    }

    &.pt-20 {
        padding-top: 20px;
    }

    figure {
        margin-right: 15px;
    }

    h2 {
        font-weight: $weight-500;
        font-size: 22px;
        letter-spacing: 0.2px;
        color: $black;
    }
}

.experience-detail-sec {
    .experience-detail-inner {
        padding-top: 30px;

        &.pt-0 {
            padding-top: 0;
        }
    }

    .product-info-box {
        @include flexprop;
        padding-bottom: 15px;

        .image-box {
            width: 64px;
            height: 64px;
            border-radius: 50%;
            background: $light-bg;
            padding: 12px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }

            &.small {
                width: 42px;
                height: 42px;
            }
        }

        .content-box {
            padding-left: 15px;
            width: calc(100% - 64px);

            &.small {
                width: calc(100% - 42px);
            }

            a {
                color: $primary600;
                font-size: 16px;
                margin-bottom: 15px;
                display: inline-block;

                &:hover {
                    text-decoration: underline;
                }
            }

            h2 {
                margin-bottom: 10px;
                font-weight: $weight-600;
                font-size: 20px;
                line-height: 24px;
                color: $black;

                &.weight-500 {
                    font-weight: $weight-500;
                }
            }

            h5 {
                margin-bottom: 15px;
                font-weight: $weight-500;
                font-size: 16px;
                line-height: 20px;
                color: rgba(91, 117, 134, 1);
                margin-top: 5px;
            }

            h4 {
                font-weight: $weight-500;
                font-size: 14px;
                line-height: 17px;
                color: rgba(39, 50, 51, 1);
            }

            p {
                font-weight: $weight-500;
                font-size: 14px;
                line-height: 22px;
                color: $gray-para;
                max-width: 360px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                margin: 16px 0;
            }
        }
    }

    hr.horozontal-border {
        width: 92%;
        margin-left: auto;
        border: 1px solid $gray300;
    }
}

.skill-contents {
    ul {
        @include flexprop;
        flex-wrap: wrap;
        padding-left: 74px;

        li {
            padding: 0 15px;
            border: 1px solid $gray300;
            border-radius: 26px;
            margin: 0 15px 10px 0;

            &:last-child {
                margin-right: 0;
            }

            span {
                padding: 18px 16px;
                font-weight: $weight-500;
                font-size: 16px;
                color: $black;
                display: inline-block;
            }
        }
    }
}

.personal-inventory-info {
    &.pt-24 {
        padding-top: 24px;
    }

    ul {
        &.pb-8 {
            padding-bottom: 8px;
        }

        padding-left: 74px;

        li {
            @include flexprop;
            flex-wrap: wrap;
            margin-bottom: 16px;

            p {
                font-weight: $weight-400;
                font-size: 16px;
                line-height: 170%;
                letter-spacing: 0.2px;
                color: $secondary;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                word-break: break-all;

                &.first-content {
                    width: 300px;
                }

                &.second-content {
                    width: calc(100% - 300px);
                    padding-left: 12px;
                }

                strong {
                    font-weight: $weight-500;
                    line-height: 170%;
                    letter-spacing: 0.2px;
                    color: $gray500;
                    word-break: break-all;
                }
            }

            &:last-child {
                margin-right: 0;
            }

            span {
                padding: 18px 16px;
                font-weight: $weight-500;
                font-size: 16px;
                color: $black;
                display: inline-block;
            }
        }
    }

    hr.horozontal-border {
        margin-left: auto;
        border: 1px solid $gray300;
    }
}

.time-chart {
    padding-left: 74px;
    padding-bottom: 30px;
}

.view-content-wrap {
    hr.horozontal-border {
        margin: 0 auto 24px;
        border: 1px solid $gray300;
    }
}

.vault-content-box {
    padding-left: 74px;

    .view-content-inner {
        padding-bottom: 24px;
    }

    p {
        &.company-description {
            margin-bottom: 8px;
            font-weight: $weight-500;
            font-size: 18px;
            line-height: 170%;
            letter-spacing: 0.2px;
            color: $gray500;
        }

        &.company-info {
            font-size: 16px;
            font-weight: $weight-400;
            line-height: 170%;
            color: $gray600;
        }
    }

    .btn-sec {
        @include flexprop;
        @include flexalign(center);
        @include flexjustify(center);
        padding: 24px 0 30px;
    }
}

.certified-sec {
    padding: 30px 0 30px 74px;

    p {
        font-weight: $weight-500;
        font-size: 14px;
        line-height: 17px;
        color: $gray500;
    }

    ul {
        @include flexprop;
        flex-wrap: wrap;
        padding-top: 15px;

        &:last-child {
            padding-top: 5px;
        }

        li {
            padding: 0 15px;
            border: 1px solid $gray300;
            border-radius: 26px;
            margin: 0 15px 10px 0;

            &:last-child {
                margin-right: 0;
            }

            span {
                padding: 18px 16px;
                font-weight: $weight-500;
                font-size: 16px;
                color: $black;
                display: inline-block;
            }
        }
    }
}

.future-resume-sec {
    padding-left: 74px;

    .product-info-box {
        @include flexprop;
        padding-bottom: 15px;

        .image-box {
            width: 64px;
            height: 64px;
            border-radius: 50%;
            background: $light-bg;
            padding: 12px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }

        .content-box {
            padding-left: 15px;
            width: calc(100% - 64px);

            h2 {
                margin-bottom: 10px;
                font-weight: $weight-600;
                font-size: 20px;
                line-height: 24px;
                color: $black;
            }

            h5 {
                margin-bottom: 8px;
                font-weight: $weight-500;
                font-size: 16px;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.6);
            }

            p {
                font-weight: $weight-500;
                font-size: 14px;
                line-height: 22px;
                color: $gray600;
                max-width: 360px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }
}

// journey-track-sec
.journey-track-wrap {
    .journey-track-head {
        @include flexprop;
        @include flexalign(center);
        @include flexjustify(space-between);

        a {
            color: $primary600;
            font-size: 16px;
            margin-bottom: 15px;
            display: inline-block;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .table-wrapper {
        background: $white;

        td {
            vertical-align: middle;
        }

        .actions {
            button {
                margin: auto;
            }
        }

        .fold {
            background: #fafdff;
            padding: 25px 40px;
            display: none;

            &.open {
                display: table-row;
            }

            .subaccordian {
                background: $white;
                border: 1px solid $gray300;
                border-radius: 10px;
                padding: 15px;
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0;
                }

                .sub-accordian-box {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 15;

                    h2 {
                        font-weight: $weight-500;
                        font-size: 18px;
                        color: $gray500;
                    }
                }

                .sub-accordian-content {
                    padding: 15px 0;
                    display: none;

                    &.open2 {
                        display: table-row;
                    }

                    .ques-ans-sec {
                        padding-bottom: 15px;
                        margin-bottom: 25px;
                        border-bottom: 1px solid $gray300;

                        &:first-child {
                            padding-top: 25px;
                        }

                        &:last-child {
                            padding-bottom: 0;
                            margin-bottom: 0;
                            border-bottom: 0;
                        }

                        h3 {
                            margin-bottom: 15px;
                            font-weight: $weight-500;
                            font-size: 18px;
                            line-height: 150%;
                            color: $gray500;
                            text-align: left;
                        }

                        p {
                            margin-bottom: 10px;
                            font-weight: $weight-400;
                            line-height: 170%;
                            color: $gray500;
                            font-size: 16px;
                            color: $secondary;
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}
.mainhead-para-wrap {
    padding-bottom: 25px;
}
.mainhead-paracont {
    margin-bottom: 15px;
    margin-left: 55px;

    .sub-accordian-content {
        padding-top: 15px;
        .ques-ans-sec {
            padding-bottom: 15px;
            h3 {
                p {
                    font-size: 16px;
                    color: #333333;
                    line-height: 18px;
                    margin-bottom: 4px;
                }
            }
            p {
                margin-bottom: 8px;
                font-weight: 500;
                font-size: 15px;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.6);
            }
        }
    }
    .accodian-action {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e4e6e8;
        padding-bottom: 15px;
        margin-bottom: 15px;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
            border-bottom: 0;
        }
    }
    p {
        margin-bottom: 0px;
        font-weight: 400;
        font-size: 18px;
    }
}

.mainhead-listcont {
    padding-left: 55px;
    .mainlistcont {
        display: inline-block;
        border: 1px solid #e4e6e8;
        border-radius: 30px;
        padding: 20px 40px;
        margin-bottom: 15px;
        &:not(:last-child) {
            margin-right: 20px;
        }
    }
}
.time-chart-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: -14px 0;
    .chart-sec {
        width: 200px;
    }
    .chart-content {
        width: calc(100% - 200px);
        .chart-content-inner {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 170%;
                letter-spacing: 0.2px;
                color: #3a4b55;
                strong {
                    font-weight: 500;
                    line-height: 170%;
                    letter-spacing: 0.2px;
                    color: #273239;
                }
            }
            span {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                margin-right: 10px;
            }
        }
    }
}
.custom-label span:nth-child(2){
    font-size: 16px !important;
    font-weight: bold !important;
    color: black !important;
  }
  .inputstylingdirection{
    flex-direction: row;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .stylingcontenttext{
    font-size: 13px;
    margin-bottom: 12px;
    font-weight: 600;
  }
  .arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }
  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  .boookingSession{
    background-color: black;
    color: white;
    font-weight: 600;
    font-size: 14px;
    padding: 10px 27px;
    margin-top: 10px;
    border-radius: 10px;
  }
