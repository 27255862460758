/*---------- validation css -------------*/
.validation-error {
    width: 100%;
    margin-top: 7px;
    font-size: 80%;
    color: $danger;
    display: block;
}

/*---------- label css -------------*/
label {
    font-size: 14px;
    color: $light-text;
    font-weight: $weight-600;
    margin-bottom: 7px;
    display: inline-block;
    &.small {
        font-size: 12px;
        color: $light-text;
        font-weight: $weight-500;
    }
    &.title {
        margin-left: 12px;
        display: inline-block;
        width: 100%;
    }
}
/*---------- input css -------------*/
.input-wrapper {
    position: relative;
    margin-bottom: 20px;
    .message {
        margin-top: 6px;
        display: inline-block;
        font-size: 14px;
    }
    &.mb-0 {
        margin-bottom: 0;
    }
    &.mt-18 {
        margin-top: 18px;
    }
}
input[type="text"],
input[type="date"],
input[type="number"],
input[type="email"],
input[type="phone"],
input[type="search"],
input[type="password"],
input[type="file"],
input[type="url"],
input[type="time"],
select,
textarea {
    @include radius(14px);
    height: 60px;
    background-color: $white;
    @include border(1px, $gray300);
    color: $gray500;
    width: 100%;
    padding: 0 19px;
    font-size: 14px;
    font-family: $font-Montserrat;
    line-height: 1.5;
    &.height {
        height: 44px;
        @include radius(5px);
        padding: 0 12px;
        &.password {
            padding-left: 45px;
        }
    }
}
input:focus,
select:focus,
textarea:focus {
    border-color: $primary600;
    background-color: #fff;
}
textarea {
    height: 128px;
    resize: none;
    padding: 12px;
    @include radius(5px);
}
input::placeholder {
    color: $gray600;
    font-size: 14px;
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    opacity: 0;
    display: none;
}
.input-w-icon .input-icon,
.input-w-icon-l .input-icon {
    position: absolute;
    top: 38px;
    height: 16px;
    max-width: 26px;
}
.input-l-icon {
    input {
        padding-left: 45px;
        position: relative;
    }
    .input-left-icon {
        top: 41px;
        left: 13px;
        position: absolute;
    }
    .input-pas-icon {
        top: 40px;
        left: 13px;
        position: absolute;
        &.small {
            top: 33px;
            left: 11px;
        }
    }
    .left-btn {
        max-width: 25px;
    }
}
.input-w-icon {
    input {
        padding-right: 45px;
    }
    .input-icon {
        top: 41px;
        right: 15px;
        &.small {
            top: 33px;
            right: 11px;
            &.time-icon {
                pointer-events: none;
            }
        }
        &.top-space {
            top: 33px;
            right: 12px;
            pointer-events: none;
        }
        img {
            &.calendar-icon {
                pointer-events: none;
            }
        }
    }
    .eye-btn {
        max-width: 25px;
        cursor: pointer;
        .eye-on {
            display: none;
        }
        &.active {
            .eye-on {
                display: block;
            }
            .eye-off {
                display: none;
            }
        }
    }
}
.input-w-icon-l {
    input {
        padding-left: 45px;
    }
    .input-icon {
        left: 12px;
    }
}
.prefix-wrap {
    input {
        padding-left: 50px;
    }
    &.big input {
        padding-left: 88px;
    }
}
.text-prefix {
    height: 44px;
    position: absolute;
    left: 0;
    top: 24px;
    @include flexprop;
    @include flexalign(center);
    padding: 0 14px;
    border-right: 1px solid $gray300;
}
.file-upload {
    @include border(1px, $gray300);
    position: relative;
    padding: 20px;
    input {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        border: 0;
        opacity: 0;
        height: 100%;
    }
    .upload-content {
        font-size: 14px;
        img {
            margin-bottom: 14px;
        }
        small {
            font-size: 12px;
        }
    }
}
select {
    background-image: url("data:image/svg+xml,%3Csvg width='20px' height='20px' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 7.5L10 12.5L15 7.5' stroke='%23667085' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: calc(100% - 12px) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    padding-right: 30px;
    &.height {
        height: 44px;
    }
}
/*---------- checkbox css -------------*/
.checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    margin-bottom: 0;
    font-weight: $weight-500;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:focus {
            border-color: $primary600;
        }
    }
    .checkmark {
        position: absolute;
        top: 50%;
        margin-top: -10px;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: $white;
        @include border(1px, $gray300);
        @include radius(6px);
        &::after {
            content: "";
            position: absolute;
            display: none;
            left: 7px;
            top: 2.75px;
            width: 5.5px;
            height: 11px;
            border-width: 0 3px 3px 0;
            border-style: solid;
            border-color: $primary600;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
        &:hover,
        &:focus {
            border-color: $primary600;
        }
    }
    input:checked ~ .checkmark:after {
        display: block;
    }
}
.checkbox-wrapper.red {
    .checkbox .checkmark {
        background-color: $primary50;
        border-color: $primary600;
        &::after {
            border-color: $primary600;
        }
    }
}
/*---------- radio css -------------*/
.radio {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    margin-bottom: 0;
    font-weight: $weight-500;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:focus {
            border-color: $primary600;
        }
    }
    .checkmark {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        margin-top: -8px;
        left: 0;
        width: 16px;
        height: 16px;
        background-color: $white;
        @include border(1px, $primary600);
        @include radius(50%);
        &::after {
            content: "";
            position: absolute;
            display: none;
            left: auto;
            top: 2.3px;
            width: 10px;
            height: 10px;
            background: $primary600;
            border-radius: 50%;
        }
        &:hover,
        &:focus {
            border-color: $primary600;
        }
    }
    input:checked ~ .checkmark:after {
        display: block;
    }
}
.radio-wrapper.red {
    .radio .checkmark {
        background-color: $primary600;
        border-color: $primary600;
        &::after {
            border-color: $primary600;
        }
    }
}
.radio-options {
    .radio-wrap {
        label {
            min-width: 120px;
            text-align: center;
            position: relative;
            left: 0;
            top: 0;
            cursor: pointer;
            height: 32px;
            margin-bottom: 0 !important;
            & + label {
                margin-left: 16px;
            }
            span {
                @include border(1px, $gray300);
                position: absolute;
                left: 0;
                top: 0;
                width: 18px;
                height: 18px;
                @include height;
                padding: 7px 20px;
            }
            input {
                position: absolute;
                left: 0;
                top: 0;
                @include width;
                @include height;
                margin: 0;
                opacity: 0;
            }
            input:checked ~ span {
                background-color: $primary600;
                color: $white;
                border-color: $primary600;
            }
        }
    }
}
.state-op-list {
    li {
        display: inline-block;
        @include border(1px, $gray300);
        padding: 7px 12px;
        font-size: 12px;
        margin-top: 10px;
        span {
            cursor: pointer;
            width: 8px;
            display: inline-block;
            margin-left: 8px;
        }
        & + li {
            margin-left: 10px;
        }
    }
}

.react-select > div > div:first-of-type {
    background-image: url("data:image/svg+xml,%3Csvg width='20px' height='20px' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 7.5L10 12.5L15 7.5' stroke='%23667085' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: calc(100% - 12px) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    padding-right: 30px;

    &:focus {
        @include box-shadow(0 0 transparent !important);
        border-color: $primary600;
    }
    &:hover {
        @include box-shadow(0 0 transparent !important);
        border-color: $primary600;
    }
    @include border(1px, $gray300);
    @include radius(5px);
    @include box-shadow(0 0 transparent !important);
    color: $gray500;
    width: 100%;
    padding: 0 12px;
    font-size: 14px;
    font-family: $font-Montserrat;
    & > div {
        padding: 0;
        height: 44px;
        & > div:first-child {
            margin: 0 0 0 0;
            padding-right: 20px;
            line-height: 2;
        }
        & > div:last-child {
            margin: -8px 0;
            padding: 0;
        }
    }
    & > div:last-child > div {
        padding-left: 10px;
    }
}

.input-range {
    margin: 30px 0 25px !important;
    .price-range {
        padding: 9px 10px;
        @include border(1px, $gray300);
        color: $black;
    }
    input {
        width: 100%;
        @include radius(8px);
        height: 8px;
        appearance: none;
        -webkit-appearance: none;
        background: $primary50;
        height: 8px;
        opacity: 1;
        margin: 20px 0 15px;
    }
    input::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        cursor: pointer;
        background: $primary600;
        @include border(5px, $white);
        @include box-shadow(0 0 8px rgba(0, 0, 0, 0.1));
        @include radius(15px);
        width: 20px;
        height: 20px;
    }
}

input {
    &.error {
        border-color: $danger;
    }
}
